<template>
    <a
        :href="product.catalog_url"
        :key="product.uuid"
        class="product-tile group"
    >
        <div class="relative w-full overflow-hidden rounded-md bg-gray-200">
            <img
                v-if="product.thumbnail_url"
                :src="product.thumbnail_url"
                class="h-full w-full object-cover object-center transition-transform duration-300 group-hover:scale-[1.03]"
                width="400"
                height="267"
                :alt="product.name + ' thumbnail'"
            />
            <span class="absolute left-2 top-2 flex items-center space-x-1.5">
                <span
                    class="whitespace-nowrap rounded-md bg-white px-2 py-1 font-semibold"
                >
                    <component
                        :is="tagIcon()"
                        class="-mt-0.5 mr-0.5 inline-block size-4"
                    ></component>
                    {{ product.tag }}
                </span>
            </span>
        </div>
        <div class="flex grow flex-col justify-start pt-4">
            <div class="flex items-center justify-between">
                <span v-if="product.category != product.tag">{{
                    product.category
                }}</span>
                <span
                    v-if="product.rating_count >= 5"
                    class="flex items-end leading-none"
                >
                    <star-icon
                        class="-mb-0.5 mr-0.5 size-4 text-orange"
                    ></star-icon>
                    <span v-if="product.rating_score" class="font-semibold"
                        ><span class="sr-only">Average rating:</span>
                        {{ parseFloat(product.rating_score).toFixed(1) }}</span
                    >
                    <span class="ml-1 text-xxs text-gray-500"
                        ><span class="sr-only">Number of ratings:</span>({{
                            product.rating_count
                        }})</span
                    >
                </span>
                <span
                    v-else-if="product.is_new"
                    class="flex items-center text-orange"
                >
                    <bolt-icon class="-mb-0.5 mr-1 size-3.5"></bolt-icon>
                    <span class="text-sm font-semibold uppercase">New</span>
                </span>
            </div>
            <div class="pt-1.5">
                <h3
                    class="text-base font-semibold leading-tight group-hover:underline"
                >
                    {{ product.name }}
                </h3>
                <div
                    v-if="product.type === 'event'"
                    class="mt-1 flex justify-start"
                >
                    <span v-if="product.duration > 0"
                        >{{ product.duration }} min</span
                    >
                    <span
                        v-if="product.minimum_participants > 0"
                        class="mx-1 text-gray-500"
                        >•</span
                    >
                    <span v-if="product.maximum_participants > 0"
                        >{{ product.minimum_participants }}-{{
                            product.maximum_participants
                        }}
                        people</span
                    >
                </div>
                <p class="my-4 text-gray-500">
                    {{ product.short_description }}
                </p>
                <span v-if="product.is_lead_form" class="font-semibold">
                    Price upon request
                </span>
                <event-price
                    v-else-if="product.type === 'event'"
                    :price="price"
                    class="font-semibold"
                ></event-price>
                <product-price
                    v-else
                    :price="price"
                    class="font-semibold"
                ></product-price>
            </div>
        </div>
    </a>
</template>
<script setup>
import { BoltIcon } from '@heroicons-v2/vue/24/solid';
import {
    DesktopComputerIcon,
    GiftIcon,
    OfficeBuildingIcon,
    TruckIcon,
    UserIcon,
} from '@heroicons/vue/outline';
import { StarIcon } from '@heroicons/vue/solid';
import EventPrice from 'components/Global/EventPrice.vue';
import ProductPrice from 'components/Global/ProductPrice.vue';

const props = defineProps({
    product: {
        type: Object,
        required: true,
    },
});

const price = {
    base: props.product.price.base,
    perPerson: props.product.price.per_person,
    discountPercentage: props.product.price.discount_percentage,
    hasRangePricing: props.product.has_range_pricing,
};

function tagIcon() {
    switch (props.product.tag_icon) {
        case 'desktop-computer':
            return DesktopComputerIcon;
        case 'gift':
            return GiftIcon;
        case 'office-building':
            return OfficeBuildingIcon;
        case 'truck':
            return TruckIcon;
        case 'user':
            return UserIcon;
    }
}
</script>
<style scoped>
:deep(.price .shipping-price) {
    @apply text-gray-500;
}
</style>
