<template>
    <div id="filter-map" class="relative m-0 p-0">
        <div class="loading"></div>
    </div>
</template>

<script setup>
import { Loader } from '@googlemaps/js-api-loader';
import AdvancedMarker from 'components/ProductList/ProductFilter/AdditionalFilters/LatLngBoundsFilter/AdvancedMarker';
import inRange from 'lodash/inRange';
import uniqueId from 'lodash/uniqueId';
import { mount } from 'mount-vue-component';
import { computed, onBeforeMount, onMounted, ref, watch } from 'vue';

const props = defineProps({
    modelValue: {
        type: Array,
        required: false,
        default: [],
    },
    center: {
        type: Object,
        required: true,
        validator(value) {
            return (
                value.hasOwnProperty('lat') &&
                value.hasOwnProperty('lng') &&
                inRange(value.lat, -90, 90) &&
                inRange(value.lng, -180, 180)
            );
        },
    },
    zoom: {
        type: Number,
        required: false,
        default: 15,
    },
    products: {
        type: Object,
        required: false,
        default: {},
    },
    highlightedProduct: {
        type: Object,
        required: false,
        default: null,
    },
});

const $emit = defineEmits(['update:modelValue']);

let map = null; //can't be a ref/reactive
const mapReady = ref(false);
const boundsChanged = ref(false);
const markers = ref({});

const toLatLngStr = (product) => {
    return product.location.lat + ',' + product.location.lng;
};

const loader = new Loader({
    apiKey: GOOGLE_MAPS_API_KEY,
    libraries: ['maps', 'core', 'marker'],
    mapIds: ['1a7cef1983f3758f'],
    version: 'quarterly',
});

let mapCenter = props.center;

const asCoordinates = computed(() => {
    if (props.modelValue?.length !== 4) {
        return null;
    }
    return {
        ne: {
            lat: props.modelValue[0],
            lng: props.modelValue[1],
        },
        sw: {
            lat: props.modelValue[2],
            lng: props.modelValue[3],
        },
    };
});
const productsByCoordinate = computed(() => {
    const result = {};
    props.products.forEach((product) => {
        if (product.location?.lat && product.location?.lng) {
            const latLngStr = toLatLngStr(product);
            if (!result[latLngStr]) {
                result[latLngStr] = [];
            }

            result[latLngStr].push(product);
        }
    });

    return result;
});

onBeforeMount(() => {
    //if there is an initial bound, need to calculate the center instead of using the default so map matches the results
    if (asCoordinates.value) {
        loader.importLibrary('core').then(({ LatLngBounds }) => {
            const initialBounds = new LatLngBounds(
                asCoordinates.value.sw,
                asCoordinates.value.ne
            );
            mapCenter = {
                lat: initialBounds.getCenter().lat(),
                lng: initialBounds.getCenter().lng(),
            };
        });
    }
});

watch(
    () => props.products,
    () => {
        updateMarkers();
    }
);
watch(
    () => props.highlightedProduct,
    (enteringProduct, exitingProduct) => {
        const product = enteringProduct || exitingProduct;
        const key = toLatLngStr(product);
        if (
            markers.value[key] &&
            !markers.value[key].el.firstChild.classList.contains('open')
        ) {
            markers.value[key].el.firstChild.classList.toggle('highlighted');
        }
    }
);

const buildMarkerNode = (products, element = null) => {
    if (!element) {
        element = document.createElement('div');
        element.id = uniqueId('marker-');
    }
    const { vNode, destroy, el } = mount(AdvancedMarker, {
        props: { products: products },
        element: element,
    });
    return { el, destroy };
};

const closeAllMarkers = () => {
    for (const [coordinate, markerInfo] of Object.entries(markers.value)) {
        markerInfo.el.firstChild.classList.remove(
            'opened',
            'below',
            'above',
            'left',
            'right',
            'center'
        );
        markerInfo.gMapMarker.zIndex = null;
    }
};

const updateMarkers = () => {
    loader
        .importLibrary('marker')
        .then(({ AdvancedMarkerElement }) => {
            for (const [coordinate, products] of Object.entries(
                productsByCoordinate.value
            )) {
                if (markers.value[coordinate]) {
                    const element = markers.value[coordinate]['el'];
                    const { el, destroy } = buildMarkerNode(products, element);
                    markers.value[coordinate]['destroy'] = destroy;
                } else {
                    const { el, destroy } = buildMarkerNode(products);
                    const gMapMarker = new AdvancedMarkerElement({
                        map: map,
                        content: el,
                        position: products[0].location,
                    });

                    markers.value[coordinate] = {
                        el: el,
                        gMapMarker: gMapMarker,
                        destroy: destroy,
                    };

                    gMapMarker.addListener('click', (e) => {
                        if (
                            !e.domEvent.defaultPrevented ||
                            e.domEvent.type !== 'click'
                        ) {
                            closeAllMarkers();
                            gMapMarker.zIndex =
                                gMapMarker.zIndex > 0 ? null : 2;

                            //when new map sizes are introducted, these values will have to be calc'd based on map width
                            el.firstChild.classList.add(
                                e.pixel.y < -75 ? 'below' : 'above',
                                e.pixel.x < -92
                                    ? 'right'
                                    : e.pixel.x > 92
                                      ? 'left'
                                      : 'center',
                                'opened'
                            );
                        }
                        //this fixes weirdness in mobile/touch events from the maps js api
                        //it seems like the preventDefault property of the domEvent is set to true so all click/touch
                        //events are doing nothing
                        if (e.domEvent.type === 'touchend') {
                            if (
                                e.domEvent.target.closest('a') &&
                                !['svg', 'path'].includes(
                                    e.domEvent.target.tagName
                                )
                            ) {
                                e.domEvent.target.closest('a').click();
                            } else if (
                                ['svg', 'path'].includes(
                                    e.domEvent.target.tagName
                                )
                            ) {
                                if (
                                    e.domEvent.target.closest(
                                        'button.splide__arrow'
                                    )
                                ) {
                                    e.domEvent.target
                                        .closest('button.splide__arrow')
                                        .click();
                                } else if (
                                    !e.domEvent.target.closest('.marker-icon')
                                ) {
                                    closeAllMarkers();
                                }
                            }
                        }
                    });
                }
            }

            //remove markers no longer in the results
            for (const [coordinate, markerInfo] of Object.entries(
                markers.value
            )) {
                if (!productsByCoordinate.value[coordinate]) {
                    markers.value[coordinate].destroy();
                    delete markers.value[coordinate];
                }
            }
        })
        .catch((e) => {
            console.error(e);
        });
};

const updateMapBounds = (ne, sw) => {
    $emit('update:modelValue', [ne.lat, ne.lng, sw.lat, sw.lng]);
};

onMounted(() => {
    //create map and attach listeners
    loader
        .importLibrary('maps')
        .then(({ Map }) => {
            map = new Map(document.getElementById('filter-map'), {
                mapId: '1a7cef1983f3758f',
                center: mapCenter,
                zoom: props.zoom,
                clickableIcons: false, //applies to google provided POIs like 'Empire State Building'
                fullscreenControl: false,
                mapTypeControl: false,
                streetViewControl: false,
            });

            const notifyIfBoundsChanged = () => {
                mapReady.value = true;
                if (boundsChanged.value) {
                    updateMapBounds(
                        map.getBounds().getNorthEast().toJSON(),
                        map.getBounds().getSouthWest().toJSON()
                    );
                    boundsChanged.value = false;
                }
            };

            map.addListener('bounds_changed', function () {
                boundsChanged.value = true;
            });

            map.addListener('idle', function () {
                notifyIfBoundsChanged();
            });

            map.addListener('click', function () {
                closeAllMarkers();
            });

            map.addListener('zoom_changed', function () {
                boundsChanged.value = true;
            });
        })
        .catch((e) => {
            console.error(e);
        });
});
</script>

<style scoped>
.loading {
    @apply absolute h-full w-full animate-pulse bg-gray-300;
}
</style>
